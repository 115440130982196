import * as React from 'react';
import Stack from '@mui/material/Stack';
import Autocomplete, {
  autocompleteClasses,
  AutocompleteProps,
} from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';

const LISTBOX_PADDING = 8;

function renderRow(
  props: ListChildComponentProps,
) {
  const { data, index, style } = props;

  const dataSet = data[index];
  
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const option = dataSet[1];

  return (
    <Stack direction="row">
      <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
        {option.label}
      </Typography>
    </Stack>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

OuterElementType.displayName = 'OuterElementType';

function useResetCache(data: number) {
  const ref = React.useRef<VariableSizeList>(null);

  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={getChildSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {(props) =>
            renderRow(props)
          }
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const StyledPaper = styled(Paper)({
  // boxShadow: 'none',  // Removes the shadow
});

interface VirtualizeAutocomplete<T>
  extends Omit<AutocompleteProps<T, true, false, true>, 'options'> {
  labelText?: string;
  options: T[];
}

const TickersAutocomplete = <T,>(props: VirtualizeAutocomplete<T>) => {
  const { options, ...rest } = props;
  return (
    <Autocomplete
      fullWidth
      id="virtualize-tickers-autocomplete"
      disableListWrap
      options={options}
      PopperComponent={StyledPopper}
      PaperComponent={StyledPaper}
      ListboxComponent={ListboxComponent}
      renderOption={(props, option, state) =>
        [props, option, state.index] as React.ReactNode
      }
      {...rest}
    />
  );
};

export default TickersAutocomplete;
