'use client'
import { useState, useMemo, useEffect } from 'react';
import { useRouter, useParams } from 'next/navigation';
import { useTranslations } from 'next-intl';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { useTickersSuspenceQuery } from '@/libs/api';
import SearchIcon from '@/libs/icons/14/search.svg';
import { TextField } from '@/libs/ui';

import TickersAutocomplete from './TickersAutocomplete';

interface Option {
  label: string;
  value: number | string;
}

const SearchTickers = () => {
  const t = useTranslations('Common');
  const params = useParams<{ symbol?: string }>();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');  

  const router = useRouter();

  const { data, isLoading } = useTickersSuspenceQuery();  

  const tickers = data?.entities;

  useEffect(() => {
    if (params?.symbol) {
      setInputValue(params.symbol);
    } else {
      setInputValue('');
    }
  }, [params?.symbol]);

  const options = useMemo<Option[]>(() => {
    if (!tickers) return [];

    return tickers.map((ticker) => ({
      value: ticker.id,
      label: ticker.symbol,
    }));
  }, [tickers]);

  const handleSelect = async (ticker: string) => {
    if (!ticker) return;

    router.push(`/tickers/${ticker}`);
  };

  return (
    <TickersAutocomplete
      freeSolo
      blurOnSelect
      clearOnBlur
      size="medium"
      onChange={(event, option) => {
        // @ts-ignore
        handleSelect(option?.label);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      disabled={isLoading}
      renderInput={(params) => (
        <TextField
          label={t('searchPlaceholder')}
          {...params}
          InputProps={{
            ...params.InputProps,
            ...(!inputValue
              ? {
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setOpen(true)}>
                          <SearchIcon color="primary" fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                }
              : {}),
          }}
        />
      )}
    />
  );
};

export default SearchTickers;
